import Vue from 'vue'
import App from './App.vue'
import router from './router.js'

import axios from "axios";
import VueAxios from "vue-axios";

import '@/assets/css/index.less';

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
