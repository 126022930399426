import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/Register.vue')
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard.vue')
    },
    {
        path: '/polityka-prywatnosci',
        name: 'PolitykaPrywatnosci',
        component: () => import('@/views/PolitykaPrywatnosci.vue')
    },
    {
        path: '/regulamin',
        name: 'Regulamin',
        component: () => import('@/views/Regulamin.vue')
    },
    {
        path: '/zgoda-marketing',
        name: 'ZgodaMarketing',
        component: () => import('@/views/ZgodaMarketing.vue')
    },
    {
        path: '/zgoda-konkurs',
        name: 'ZgodaKonkurs',
        component: () => import('@/views/ZgodaKonkurs.vue')
    },
    {
        path: '/quiz/:id',
        name: 'Quiz',
        component: () => import('@/views/Quiz.vue')
    },
    {
        path: '/crossword/:id',
        name: 'Crossword',
        component: () => import('@/views/Crossword.vue')
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  const publicPages = [
    "/",
    "/login",
    "/register",
    "/regulamin",
    "/polityka-prywatnosci",
    '/zgoda-marketing',
    '/zgoda-konkurs'
  ];

  const authRequired = !publicPages.includes(to.path);
  const token = localStorage.getItem("token");

  if (authRequired && !token) {
    return next({
      path: "/"
    });
  }

  next();
});

export default router
